/* eslint-disable consistent-return, array-callback-return */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import Slider from 'components/Slider';
import { Inner } from 'containers/Wrapper';
import getContentBySubstring from 'utils/getContentBySubstring';
import {
  Background,
  OuterWrapper,
  Heading,
  TopTear,
  BottomTear,
  Wrapper,
  QuotesWrapper,
  SlideBody,
  SlideCaption
} from './styled';

const Carousel = ({ tear, heading, substring, intl: { messages } }) => (
  <Background>
    {tear === 'both' || tear === 'top' ? <TopTear /> : null}
    <OuterWrapper>
      <Inner>
        <Wrapper>
          <Row center="xs">
            <Col xs={11} sm={12}>
              <Heading>
                <FormattedMessage id={heading} />
              </Heading>
            </Col>
          </Row>
          <Row center="xs">
            <Col xs={11} sm={7}>
              <QuotesWrapper>
                <Slider>
                  {getContentBySubstring(messages, substring).map(
                    (quote, i, substrings) => {
                      if (i < substrings.length / 2) {
                        return (
                          <div key={substring}>
                            <SlideBody>
                              <FormattedMessage
                                id={`${substring}Body${i + 1}`}
                              />
                            </SlideBody>
                            <SlideCaption>
                              <FormattedMessage
                                id={`${substring}Caption${i + 1}`}
                              />
                            </SlideCaption>
                          </div>
                        );
                      }
                    }
                  )}
                </Slider>
              </QuotesWrapper>
            </Col>
          </Row>
        </Wrapper>
      </Inner>
    </OuterWrapper>
    {tear === 'both' || tear === 'bottom' ? <BottomTear /> : null}
  </Background>
);

Carousel.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  heading: PropTypes.string,
  substring: PropTypes.string,
  tear: (props, propName, componentName) => {
    if (!/null|both|top|bottom/.test(props[propName])) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Validation failed`
      );
    }
  }
};

Carousel.defaultProps = {
  heading: null,
  substring: null,
  tear: null
};

export default injectIntl(Carousel);
