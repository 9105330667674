import React, { Fragment } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import CountTo from 'modules/CountTo';
import FactBubble from 'components/FactBubble';
import InfoBox from 'components/InfoBox';
import { Outer, Inner } from 'containers/Wrapper';
import { Wrapper, CalloutStampBlock } from './styled';

const Block = () => (
  <Outer>
    <Inner>
      <Wrapper>
        <Row>
          <Col xs={12} sm={12}  md={6}>
            <FactBubble />
          </Col>
          <Col xs={12} smOffset={1} sm={8} mdOffset={0} md={6}>
            <InfoBox>
              <FormattedHTMLMessage id="conditions_infoJobparents" />
            </InfoBox>
          </Col>
        </Row>
      </Wrapper>
      <CalloutStampBlock
        stamp="fingerprint"
        calloutId="conditions_calloutHoweverimprove"
      />
      <CountTo
        counters={[
          {
            key: 34,
            number: 34,
            units: {
              one: 'conditions_visRatified183',
              other: 'conditions_visRatified183'
            }
          }
        ]}
      />
    </Inner>
  </Outer>
);

export default Block;
