/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape
} from 'react-intl';

import Select from 'components/Select';
import {
  Chart,
  ChartFill,
  ChartLabel,
  ChartWrapper,
  CountryName,
  DataSource,
  /* DataText, */
  Main,
  SelectSection,
  Title,
  Wrapper,
  LegendWrapper,
  LegendItem,
  Circle
} from 'modules/WorkingHours/styled';
import getIPLookup from 'utils/getIPLookup';

class WorkingHours extends React.Component {
  static allowedLanguages = ['en', 'fr', 'es'];

  state = {
    selectedOption: null
  };

  componentDidMount = async () => {
    const {
      countries,
      intl: { locale: language }
    } = this.props;

    try {
      const result = await getIPLookup();
      const item = countries[language].filter(
        ({ code }) => code === result.data.countryCode
      );
      if (item.length > 0) {
        const selectedOption = item[0];
        this.setState({ selectedOption });
      } else {
        throw new Error('Country by IP is missing');
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };

  getSelectOptions = items => {
    const {
      countries,
      intl: { locale: language }
    } = this.props;
    const countriesAvailable = items.map(({ node }) => node.country);

    return countries[language].filter(
      ({ value }) => countriesAvailable.indexOf(value) !== -1
    );
  };

  getValueByCountry = (items, hours, { value }) => {
    const itemByCountry = items.filter(item => item.node.country === value)[0];

    return parseFloat(itemByCountry.node[hours]).toFixed(1);
  };

  getAverageValueWorld = (items, hours) => {
    const total = items.reduce(
      (acc, current) => acc + parseFloat(current.node[hours]),
      0
    );

    return (total / items.length).toFixed(1);
  };

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  render() {
    const {
      intl: { locale: language }
    } = this.props;
    const { selectedOption } = this.state;

    if (!WorkingHours.allowedLanguages.includes(language)) return null;

    return (
      <StaticQuery
        query={graphql`
          query WorkingHoursQuery {
            allWorkingHours {
              edges {
                node {
                  lessThan30
                  moreThan48
                  between30And48
                  country
                }
              }
            }
          }
        `}
        render={data => {

          const {
            allWorkingHours: { edges: items }
          } = data;

          const options = this.getSelectOptions(items);

          const valueLessThan30 = options.includes(selectedOption)
            ? this.getValueByCountry(items, 'lessThan30', selectedOption)
            : this.getAverageValueWorld(items, 'lessThan30');

          const valueBetween30And48 = options.includes(selectedOption)
            ? this.getValueByCountry(items, 'between30And48', selectedOption)
            : this.getAverageValueWorld(items, 'between30And48');

          const valueMoreThan48 = options.includes(selectedOption)
            ? this.getValueByCountry(items, 'moreThan48', selectedOption)
            : this.getAverageValueWorld(items, 'moreThan48');

          return (
            <Wrapper style={{ width: 300 }}>
              <Main>
                <Title>
                  <FormattedMessage id="conditions_visTimeTitle" />
                </Title>

                <SelectSection>
                  <Select
                    value={options.includes(selectedOption) && selectedOption}
                    isClearable={false}
                    placeholder={
                      <FormattedMessage id="conditions_visTimeSelect" />
                    }
                    onChange={this.handleSelectChange}
                    options={options}
                    noOptionsMessage={() => (
                      <FormattedMessage id="interface_noOptions" />
                    )}
                  />
                </SelectSection>

                <CountryName>
                  {options.includes(selectedOption)
                    ? selectedOption.label
                    : 'World'}
                </CountryName>

                <ChartWrapper>
                  <ChartLabel>
                    <FormattedMessage id="conditions_visTimeSharesLabel" />
                  </ChartLabel>

                  <Chart>
                    <ChartFill value={valueLessThan30} hours="lessThan30">
                      <span>{valueLessThan30}</span>
                    </ChartFill>
                    <ChartFill
                      value={valueBetween30And48}
                      hours="between30And48"
                    >
                      <span>{valueBetween30And48}</span>
                    </ChartFill>
                    <ChartFill value={valueMoreThan48} hours="moreThan48">
                      <span>{valueMoreThan48}</span>
                    </ChartFill>
                  </Chart>
                </ChartWrapper>

                <LegendWrapper>
                  <LegendItem>
                    <Circle hours="lessThan30" />
                    <FormattedHTMLMessage id="conditions_visTimeColour1" />
                  </LegendItem>
                  <LegendItem>
                    <Circle hours="between30And48" />
                    <FormattedHTMLMessage id="conditions_visTimeColour2" />
                  </LegendItem>
                  <LegendItem>
                    <Circle hours="moreThan48" />
                    <FormattedHTMLMessage id="conditions_visTimeColour3" />
                  </LegendItem>
                </LegendWrapper>
                <DataSource>
                  <FormattedMessage id="conditions_visTimeNote" />
                </DataSource>
              </Main>
            </Wrapper>
          );
        }}
      />
    );
  }
}

WorkingHours.propTypes = {
  countries: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: intlShape.isRequired
};

export default injectIntl(WorkingHours);
