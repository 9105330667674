import styled, { css } from 'styled-components';

import media from 'styles/media';
import {H2} from "styles/typography";
import {titleSans, mineSahft, white, serif, mineSahft1, sans, codGray, magenta, grey, black, teal, offblue, grime} from "styles/variables";

const getColorByHours = (hours) => {
  switch (hours) {
    case 'lessThan30':
      return teal;
    case 'between30And48':
      return offblue;
    default:
      return magenta
  }
};

export const Wrapper = styled.a`
  min-height: 100vh;
  height: auto;
  width: 100%;
  background-color: ${white};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Title = styled(H2)`
  font-size: 3.1rem;
  color: ${mineSahft};
  text-align: center;
  text-transform: uppercase;
  padding: 4rem 0;
  margin: 0;
  font-family: ${titleSans};

  ${media.sm`
    font-size: 2.1rem;
  `};

  ${media.xs`
    text-align: left;
    font-size: 30px;
    padding: 4.5rem 0 26px 0;
  `};
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 10%;
  margin: 0 auto 0 auto;
  background-color: white;

  @media screen and (max-width: 1366px) {
    width: 100%;
    padding: 0 55px 0 0;
  }

  @media screen and (max-width: 751px) {
    margin: 0 auto 0 auto;
    padding: 25px;
  }
`;

export const SelectSection = styled.div`
  text-align: center;
  max-width: 400px;
  margin: 0 auto 0 auto;
  position: relative;
`;

export const CountryName = styled.h3`
  text-align: center;
  font-size: 2.5rem;
  font-family: ${serif};
  color: ${mineSahft1};
  margin-top: 50px;
  margin-bottom: 25px;
  font-weight: 400;

  ${media.xs`
    text-align: left;
    font-size: 30px;
    margin-top: 26px;
  `};
`;

export const ChartWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  padding-bottom: 70px;

  ${media.xs`
    width: 100%;
    padding-bottom: 30px;
  `};
`;

export const ChartLabel = styled.div`
  font-family: ${sans};
  font-size: 16px;
  color: ${codGray};
  margin-bottom: 15px;
  font-weight: 400;

  ${media.sm`
    margin-bottom: 14px;
  `};

  ${media.xs`
    margin-bottom: 50px;
  `};
`;

const smallValueStylesLeft = css`
  position: absolute;
  left: -70px;
  color: ${teal};
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: 752px) and (max-width: 1024px) {
    left: -60px;
  }
`;

const smallValueStylesRight = css`
  position: absolute;
  right: -70px;
  color: ${magenta};
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: 752px) and (max-width: 1024px) {
    right: -60px;
  }
`;

export const ChartFill = styled.div`
  width: ${props => props.value || 0}%;
  transition: width 0.4s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 2.8rem;
  font-family: ${titleSans};
  font-weight: 700;
  background: ${props => props.hours && getColorByHours(props.hours)};
  background-size: contain;
  background-position: 15px 6px;
  span {
    @media screen and (min-width: 752px) {
      ${props => props.value < 10 && props.hours === 'lessThan30' && smallValueStylesLeft};
      ${props => props.value < 10 && props.hours === 'moreThan48' && smallValueStylesRight};
    }
  }

  ${media.md`
    font-size: 35px;
  `};

  ${media.sm`
    font-size: 30px;
  `};

  ${media.xs`
    font-size: 20px;
    background-position: 5px 6px;
    span {
      position: absolute;
      color: ${props => props.hours && getColorByHours(props.hours)};
      top: -30px;
    }
  `};
`;

export const Chart = styled.div`
  font-family: ${sans};
  font-size: 14px;
  color: ${white};
  font-weight: 400;
  background-color: ${grey};
  margin-bottom: 25px;
  height: 100px;
  position: relative;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.sm`
    height: 72px;
  `};

  ${media.xs`
    height: 26px;
  `};
`;

export const LegendWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;

  ${media.xs`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  `};
`;

export const LegendItem = styled.div`
  height: 30px;
  font-family: ${sans};
  display: flex;
  font-size: 16px;
  line-height: 26px;
  width: 25%;

  ${media.sm`
    font-size: 14px;
    line-height: 24px;
  `};

  ${media.xs`
    font-size: 13px;
    height: auto;
    width: auto;
  `};
`;

export const Circle = styled.div`
  background: ${props => props.hours && getColorByHours(props.hours)};
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  margin: 20px 10px 0 0;

  ${media.xs`
    margin: 15px 10px 0 0;
  `};
`;

export const DataSource = styled.div`
  margin: 110px 0 30px 0;
  color: ${black};
  font-family: ${sans};
  font-size: 16px;
  text-align: center;

  ${media.sm`
    margin-top: 130px;
    font-size: 14px;
  `};

  ${media.xs`
    margin-top: 30px;
    text-align: left;
    font-size: 13px;
  `};
`;

export const DataText = styled.div`
  color: ${grime};
  font-family: ${sans};
  font-size: 14px;
  text-align: center;
  width: 70%;
  margin: 0 auto 20px auto;
  line-height: 1.6;

  ${media.xs`
    text-align: left;
    width: 100%;
    font-size: 12px;
  `};
`;
