import React from 'react';
import MovingImage from 'components/MovingImage';
import Wrapper from './styled';

const Block = () => (
  <Wrapper>
    <MovingImage
      overlay
      overlayCaption
      overlayMessage="conditions_calloutButpoor"
      videoID="conditions/videoRanaPlaza"
      soundID="conditions/rana-plaza-sound"
    />
  </Wrapper>
);

export default Block;
