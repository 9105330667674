import styled from 'styled-components';
import Tear from 'components/Tear';
import { cobalt, white, rose, sans, serif } from 'styles/variables';
import { H2, P } from 'styles/typography';
import media from 'styles/media';
import { Outer } from 'containers/Wrapper';

export const Background = styled.div`
  width: 100%;
  min-width: 100vw;
  height: 55rem;
  background-color: ${cobalt};
  position: relative;

  ${media.md`
    height: 55rem;
  `};

  ${media.xs`
    display: inline-block;
    height: auto;
  `};
`;

export const OuterWrapper = styled(Outer)`
  height: 100%;
`;

export const TopTear = styled(Tear)`
  transform: scaleY(-1) scaleX(-1);
  bottom: initial;
  top: -1px;
`;

export const BottomTear = Tear;

export const Wrapper = styled.section`
  position: relative;
  padding: 10rem 0 0 0;
  height: 100%;

  ${media.xs`
    padding: 6rem 0 0 0;
  `};
`;

export const QuotesWrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 19rem;

  ${media.xs`
    min-height: 35rem;
  `};
`;

export const Heading = styled(H2)`
  font-weight: 400;
  color: ${rose};
  text-align: center;
  width: 100%;
  margin: 3rem 0 6rem;

  ${media.xs`
    font-family: ${sans};
    text-align: left;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 1.5rem;
  `};
`;

export const Keys = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: absolute;
  bottom: 3rem;
  width: 100%;
  pointer-events: none;

  ${media.xs`
    bottom: 1rem;
  `};
`;

export const Key = styled.img`
  display: inline-block;
  height: 80px;
  flex: 0 0 auto;
  margin: 0 15px;

  ${media.xs`
    height: 100px;

     &:nth-of-type(1) {
      display: none;
    }

    &:nth-of-type(3) {

      display: none;
    }
  `};
`;

export const SlideBody = styled(P)`
  color: ${white};
  font-family: ${serif};
  font-size: 1.625rem;
  text-align: center;
  margin: 0 0 3rem;

  ${media.md`
    font-size: 1.375rem;
    line-height: 2rem;
  `};

  ${media.xs`
    text-align: left;
    font-size: 1.375rem;
    line-height: 2rem;
  `};
`;

export const SlideCaption = styled(P)`
  color: ${white};
  text-align: center;

  ${media.xs`
    text-align: left;
  `};
`;

export default Background;
