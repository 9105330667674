import styled from 'styled-components';
import { yellow, black, serif, white } from 'styles/variables';
import { h1Mixin, h3Mixin, paragraphMixin } from 'styles/typography';
import media from 'styles/media';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: inline-block;

  ${media.sm`
    height: 400px;
  `};
`;

export const Content = styled.div`
  position: relative;
  width: 245px;
  height: 245px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition: transform ease 1000ms;
  transform: ${props =>
    props.isVisible ? 'translateY(100%)' : 'translateY(0)'};

  ${media.sm`
    transform: none;
  `};

  ${media.xs`
    width: 100%;
    height: 93vw;
    transform: none;
    padding: 0 10vw;
    margin: 2rem 0;
  `};

  .bubble {
    position: absolute;
    top: 0;
    right: -18%;
    bottom: 0;
    margin: auto;
    width: 330px;
    height: 330px;
    background-color: ${yellow};
    border-radius: 50%;
    z-index: 0;
    transition: transform ease 1000ms, opacity ease 1000ms;
    transform: ${props => (props.isVisible ? 'scale(1.3)' : 'scale(1)')};
    opacity: ${props => (props.isVisible ? 1 : 0.9)};

    ${media.xs`
      left: 0;
      top: 0;
      margin: 0;
      width: 93vw;
      height: 93vw;
      transform: none;
    `};
  }

  .figure {
    ${h1Mixin};
    color: ${yellow};
    margin: 0.5rem 0;
    padding: 0;
    letter-spacing: -5px;
    color: ${white};
    z-index: 1;

    &:after {
      content: '%';
    }

    ${media.xs`
      font-size: 7rem;
      line-height: 7.2rem;
    `};
  }

  h3 {
    ${h3Mixin};
    font-size: 2rem;
    display: block;
    line-height: 3.25rem;
    font-weight: 400;
    color: ${black};
    word-break: break-word;
    font-family: ${serif};
    margin: 0;
    text-align: center;
    z-index: 1;

    ${media.md`
      font-size: 2rem;
      line-height: 2.5rem;
    `};

    ${media.sm`
      font-size: 1.875rem;
    `};

    ${media.xs`
      line-height: 1;
    `};
  }

  p {
    ${paragraphMixin};
    font-size: 0.95rem;
    line-height: 1.3rem;
    font-weight: 400;
    width: 100%;
    display: inline-block;
    text-align: center;
    z-index: 1;
    color: ${black};

    ${media.xs`
      line-height: 1.2rem;
    `};

    &:last-child {
      margin: 0;
    }
  }
`;
