/* eslint-disable no-nested-ternary */

import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import Count from 'components/FactBubble/Count';
import withResponsive from 'containers/Responsive/withResponsive';
import { Wrapper, Content } from './styled';

function FactBubble({ number, year, isMobile, windowWidth }) {
  const [ready, setReady] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);

  const wrapperRef = useRef();

  const handleVisibilityChange = isVisible => {
    setReady(isVisible);
  };

  const handleResize = () => {
    if (window.innerWidth > 768) {
      return setContainerHeight(wrapperRef.current.clientHeight);
    }
    return setContainerHeight(wrapperRef.current.clientHeight * 2);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return window.removeEventListener('resize', handleResize);
  }, []);

  const count = isMobile ? number[1] : ready ? number[1] : number[0];
  const currentYear = isMobile ? year[1] : ready ? year[1] : year[0];

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      minTopValue={containerHeight}
      delayedCall
      active={!isMobile}
    >
      {({ isVisible }) => (
        <Wrapper ref={wrapperRef}>
          <Content isVisible={isVisible}>
            <div className="bubble" />
            <h3>
              <FormattedMessage id={currentYear} />
            </h3>
            <Count count={count}>
              {value => <div className="figure">{Math.floor(value.x)}</div>}
            </Count>
            <p>
              <FormattedMessage id="conditions_visCountriesmaternity" />
            </p>
          </Content>
        </Wrapper>
      )}
    </VisibilitySensor>
  );
}

FactBubble.propTypes = {
  number: PropTypes.arrayOf(PropTypes.number),
  year: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired
};

FactBubble.defaultProps = {
  number: [38, 51],
  year: ['conditions_vis1994', 'conditions_vis2013']
};

export default withResponsive(FactBubble);
