import styled from 'styled-components';
import CalloutStamp from 'modules/CalloutStamp';
import media from 'styles/media';

export const Wrapper = styled.div`
  height: auto;
  margin: 10rem 0;

  ${media.md`
    margin: 8rem 0 5rem;
  `};

  ${media.sm`
    margin: 10rem 0 3rem;
  `};

  ${media.xs`
    margin: 1rem 0 3rem;
  `};
`;

export const CalloutStampBlock = styled(CalloutStamp)`
  margin: 5rem 0 0;

  ${media.xs`
    margin: 0;
  `};
`;