import React from 'react';
import { Motion, spring } from 'react-motion';
import PropTypes from 'prop-types';

const Count = ({ count, children }) => (
  <Motion
    defaultStyle={{ x: 0 }}
    style={{
      x: spring(count, { precision: 1, stiffness: 80, damping: 30 })
    }}
  >
    {value => children(value)}
  </Motion>
);

Count.propTypes = {
  count: PropTypes.number,
  children: PropTypes.node
};

Count.defaultProps = {
  count: 0,
  children: null
};

export default Count;
