import React from 'react';
import { theme } from 'config/conditions.json';
import { withIntl } from 'i18n';
import Template from 'modules/ChapterTemplate';
import Conclusion from 'modules/Conclusion';
import InfoParallax from 'modules/InfoParallax';
import CalloutStampBlock from 'modules/CalloutStamp';
import InfoBoxBlock from 'modules/InfoBox';
import Videos from 'modules/Videos';
import InfoCollage from 'modules/InfoCollage';
import WorkingHours from 'modules/WorkingHours';
import Carousel from 'components/Carousel';
import BlockMaternity from 'blocks/conditions/Block-Maternity';
import BlockMovingImage from 'blocks/conditions/Block-MovingImage';
import countries from 'config/conventions/countries.json';

const Conditions = () => (
  <Template theme={theme}>
    <InfoBoxBlock
      id="conditions_infoWhenwego"
      infobox={{
        scroll: true,
        size: {
          lg: '27rem',
          md: '21rem'
        }
      }}
      col={{
        xs: 12,
        sm: 7,
        smOffset: 2,
        md: 6,
        mdOffset: 3
      }}
    />
    <CalloutStampBlock
      stamp="pinkcircle"
      calloutId="conditions_calloutTheseshouldnot"
      subtextId="conditions_calloutManymillions"
    />
    <Videos videos="conditions/videoAsbestos" />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 9,
          smOffset: 3,
          md: 9,
          mdOffset: 4,
          lg: 8
        },
        image: 'conditions/construction-vietnam',
        caption: 'conditions_captionVietnam',
        captionPadding: {
          lg: '0 0 0 0',
          md: '0 0 0 0',
          sm: '0 0 0 0'
        }
      }}
      infoBox={{
        infoBox: {
          scroll: true,
          size: {
            lg: '30rem',
            md: '25rem',
            sm: '20rem'
          }
        },
        colProps: {
          xs: 12,
          sm: 7,
          smOffset: 0,
          md: 6,
          lg: 6,
          mdOffset: 0
        },
        message: 'conditions_infoSafetyinworkplace',
        justify: {
          sm: 'flex-start',
          md: 'flex-start',
          lg: 'flex-start'
        }
      }}
      voffset={{
        lg: '5rem 0',
        md: '10rem 0 0',
        sm: '20rem 0 0',
        xs: '0'
      }}
    />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          md: 10,
          lg: 10,
          mdOffset: 4
        },
        layers: [
          'conditions/worker-factory-1',
          'conditions/worker-factory-2',
          'conditions/worker-factory-3'
        ],
        caption: {
          message: 'conditions_imageFactoryCaption',
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 2,
            mdOffset: 4,
            md: 6
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            id: 'conditions_infoIloflagship',
            detailMessages: [
              'conditions_detailVisionzero1',
              'conditions_detailVisionzero2'
            ],
            detailFigure: {
              image: 'conditions/detail-visionzerofund',
              caption: 'conditions_imageVisionCaption'
            },
            scroll: true,
            size: {
              lg: '30rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 1,
            md: 6,
            mdOffset: 0,
            lg: 6
          },
          message: 'conditions_infoIloflagship',
          justify: 'flex-start',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '0 0 0rem',
        lg: '5rem 0 5rem',
        md: '10rem 0 0'
      }}
    />
    <Videos videos="conditions/videoBelser" />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 12,
          smOffset: 0,
          md: 12,
          mdOffset: 0
        },
        image: 'conditions/protest-madagascar',
        caption: 'conditions_imageMadagascarCaption',
        captionPadding: {
          lg: '0 65% 0 0',
          md: '0 60% 0 0',
          sm: '0 65% 0 0'
        }
      }}
      infoBox={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '25rem',
              sm: '20rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 7,
            smOffset: 0,
            md: 6,
            mdOffset: 5,
            lgOffset: 6,
            lg: 6
          },
          message: 'conditions_infoHowfaryourmoney',
          justify: {
            sm: 'flex-start',
            md: 'flex-start',
            lg: 'flex-start'
          }
        },
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '25rem',
              sm: '20rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 7,
            smOffset: 4,
            md: 5,
            mdOffset: 5
          },
          message: 'conditions_infoLimitinghours',
          justify: {
            sm: 'flex-end',
            md: 'flex-end',
            lg: 'flex-end'
          }
        }
      ]}
      voffset={{
        lg: '20rem 0 15rem',
        md: '20rem 0 8rem',
        sm: '25rem 0 4rem',
        xs: '0'
      }}
    />

    <WorkingHours countries={countries} />

    <BlockMaternity />

    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 11,
          smOffset: 2,
          md: 8,
          mdOffset: 4
        },
        image: 'conditions/telework',
        caption: 'conditions_imagePlatformCaption',
        captionPadding: {
          lg: '0',
          md: '0',
          sm: '0'
        }
      }}
      infoBox={{
        infoBox: {
          scroll: true,
          size: {
            lg: '30rem',
            md: '25rem',
            sm: '20rem'
          }
        },
        colProps: {
          xs: 12,
          sm: 7,
          smOffset: 0,
          md: 6,
          mdOffset: 0
        },
        message: 'conditions_infoNewways',
        justify: {
          sm: 'flex-start',
          md: 'flex-start',
          lg: 'flex-start'
        }
      }}
      voffset={{
        lg: '18rem 0 0',
        md: '12rem 0 0',
        sm: '22rem 0 0',
        xs: '0'
      }}
    />
    <Carousel
      tear="both"
      heading="conditions_carouselIsyourjob"
      substring="conditions_carouselYes"
    />
    <CalloutStampBlock
      stamp="pinkcircle"
      calloutId="conditions_calloutNonstdemp"
      subtextId="conditions_callout150Countries"
    />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          mdOffset: 3,
          md: 11,
          lgOffset: 4,
          lg: 10
        },
        layers: [
          'conditions/worker-catering-1',
          'conditions/worker-catering-2',
          'conditions/worker-catering-3'
        ],
        caption: {
          message: 'conditions_imageCateringCaption',
          colProps: {
            xs: 12,
            sm: 8,
            smOffset: 2,
            mdOffset: 4,
            lgOffset: 4
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '28rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 8,
            smOffset: 2,
            md: 5,
            mdOffset: 0,
            lg: 6,
            lgOffset: 0
          },
          message: 'conditions_infoNonstdjobs',
          justify: 'flex-start',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        lg: '10rem 0 0',
        md: '6rem 0 0',
        sm: '0'
      }}
    />
    <Videos videos="conditions/videoAllison" />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 10,
          smOffset: 3,
          md: 8,
          mdOffset: 0
        },
        image: 'conditions/garment-haiti',
        caption: 'conditions_captionGarmentHaiti'
      }}
      infoBox={{
        infoBox: {
          scroll: true,
          size: {
            lg: '35rem',
            md: '25rem',
            sm: '20rem'
          }
        },
        colProps: {
          xs: 12,
          sm: 6,
          md: 6,
          mdOffset: 6,
          lgOffset: 6
        },
        message: 'conditions_infoSourcing',
        justify: 'flex-start'
      }}
      voffset={{
        lg: '15rem 0 0',
        md: '15rem 0 0',
        sm: '15rem 0 0',
        xs: '0'
      }}
    />
    <InfoBoxBlock
      id="conditions_infoInmanycountries"
      col={{
        xs: 12,
        sm: 10,
        smOffset: 1,
        md: 6,
        mdOffset: 3
      }}
    />
    <BlockMovingImage />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 11,
          smOffset: 2,
          md: 10,
          mdOffset: 0,
          lg: 8
        },
        image: 'conditions/garment-bangladesh',
        caption: 'conditions_captionGarmentBang'
      }}
      infoBox={{
        infoBox: {
          id: 'conditions_infoCollapseranaplaza',
          detailMessages: [
            'conditions_detailBetterwork1',
            'conditions_detailBetterwork2'
          ],
          detailFigure: {
            image: 'conditions/detail-betterwork',
            caption: 'conditions_imageBetterworkCaption'
          },
          scroll: true,
          size: {
            lg: '28rem',
            md: '25rem',
            sm: '20rem'
          }
        },
        colProps: {
          xs: 12,
          sm: 7,
          md: 6,
          mdOffset: 5,
          lgOffset: 6,
          lg: 6
        },
        message: 'conditions_infoCollapseranaplaza',
        justify: 'flex-start'
      }}
      voffset={{
        lg: '5rem 0 0',
        md: '15rem 0 0',
        sm: '15rem 0 0',
        xs: '0'
      }}
    />
    <InfoBoxBlock
      id="conditions_infoChallengesarise"
      col={{
        xs: 12,
        sm: 10,
        smOffset: 1,
        md: 6,
        mdOffset: 3
      }}
    />
    <CalloutStampBlock
      stamp="circle"
      calloutId="conditions_calloutSolution"
      subtextId="conditions_calloutThroughitswork"
    />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 12,
          smOffset: 0,
          md: 9,
          mdOffset: 3
        },
        image: 'conditions/myanmar-safety',
        caption: 'conditions_captionMyanmarSafety',
        captionPadding: {}
      }}
      infoBox={{
        colProps: {
          xs: 12,
          smOffset: 2,
          sm: 7,
          mdOffset: 0,
          md: 5,
          lg: 6
        },
        message: 'conditions_infoTheilohelps',
        justify: {
          sm: 'flex-start',
          md: 'flex-start',
          lg: 'flex-start'
        }
      }}
      voffset={{
        lg: '0',
        md: '10rem 0 0',
        sm: '25rem 0 0',
        xs: '0'
      }}
    />
    <Conclusion
      mainTextId="conditions_calloutPromotingsafe"
      subTextId="conditions_calloutFromthefactories"
    />
  </Template>
);

export default withIntl(Conditions);
