import styled from 'styled-components';
import media from 'styles/media';

export default styled.div`
  height: auto;
  width: 100%;
  margin: 5rem 0;

  ${media.xs`
    margin: 2rem 0;
  `};
`;